/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function longDate(dateString) {
  const parsedDateString = dateString + 'T00:00:00';
  const date = new Date(parsedDateString);
  const locale = "en";

  const month = date.toLocaleString(locale, { month: 'long'});
  const day = date.toLocaleString(locale, {day: 'numeric'});
  const year = date.toLocaleString(locale, {year: 'numeric'});

  return `${day} ${month}`;
}