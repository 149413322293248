export const trackAnalytics = (() => {
  return (data) => {
    // Get current search query
    const searchQuery = data.query ? data.query : data.core?.storage?.storage?.get('query');

    const storageKey = `yextSearchCount_${searchQuery}`;
    if(sessionStorage.getItem(storageKey)) {
      sessionStorage.setItem(storageKey, parseInt(sessionStorage.getItem(storageKey)) + 1);
    } else {
      sessionStorage.setItem(storageKey, 1);
    }

    // Get number of results
    let numResults = 0;
    if(typeof data.resultsCount == 'number') {
      numResults = data.resultsCount;
    } else if(data.core?.storage?.storage?.get('universal-results')?.sections.length > 0) {
      for(const section of data.core.storage.storage.get('universal-results').sections) {
        numResults += section.resultsCount;
      }
    }

    //Get autocomplete options
    let autocompleteOptions = [];
    if(data.core?.storage?.storage?.get('autocomplete.SearchBar.autocomplete')?.sections[0]?.results.length > 0) {
      for(const option of data.core.storage.storage.get('autocomplete.SearchBar.autocomplete').sections[0].results) {
        autocompleteOptions.push(option.value);
      }
    }

    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('query', searchQuery || '');
    if(!queryParams.get('referrerPageUrl')) {
      queryParams.set('referrerPageUrl', '');
    }

    // Add info to data object
    const dataObj = {
      'event': 'view_search_results',
      'search_results': numResults,
      'search_refinements': sessionStorage.getItem(storageKey),
      'search_output': `${currentUrl.origin}${currentUrl.pathname}?${queryParams.toString()}`,
      'search_suggestion': autocompleteOptions.includes(searchQuery)
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataObj);
  }
})()
